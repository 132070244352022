// GLOBAL SCSS VARIABLES
$body-font-family: 'Roboto, "Helvetica Neue", sans-serif';
$heading-font-family: 'Roboto, "Helvetica Neue", sans-serif';

// Brand palette
$brand-primary-light-25: #f1f5f7;
$brand-primary-light-50: #e8f5e9;
$brand-primary-light-100: #c8e6c9;
$brand-primary-light-200: #a5d6a7;
$brand-primary-light: #4caf50;
$brand-primary: #007a3c;
$brand-primary-dark: #005424;
$brand-accent-light-200: #f2b980;
$brand-accent-light-100: #f7d5b3;
$brand-accent-light: #ed9c4d;
$brand-accent: #da5500;
$brand-accent-dark: #d34200;
$brand-warning-light: #ff5722;
$brand-warning: #ff5722;
$brand-warning-light-100: #ffd8cc;
$brand-warning-light-200: #ffb199;
$brand-warning-dark: #ff5722;
$brand-gray-lighter: #f5f5f5;
$brand-gray-light: #CCCCCC;
$brand-gray: #999999;
$brand-gray-dark: #666666;
$brand-text-dark: #212121;

// Categories
$brand-category-learn: #c26a4e;
$brand-category-propose: #6f8cb8;
$brand-category-conduct: #bc9025;
$brand-category-connect: #708938;

$ease-duration: 300ms;
$animation-duration: 500ms;

// Whitespace
$padding-size: 20px;
$border-top-size: 10px;
$icon-size: 64px;
$institution-logo-size: 120px;

// Typography
$base-font-size: 16px;
$font-size-larger-8: $base-font-size * 2.5;
$font-size-larger-7: $base-font-size * 2.25;
$font-size-larger-6: $base-font-size * 2;
$font-size-larger-5: $base-font-size * 1.75;
$font-size-larger-4: $base-font-size * 1.625;
$font-size-larger-3: $base-font-size * 1.5;
$font-size-larger-2: $base-font-size * 1.375;
$font-size-larger-1: $base-font-size * 1.25;

// Gradients
$brand-overlay-gradient: linear-gradient(
  to bottom,
  $brand-accent,
  $brand-primary
);

$brand-overlay-gradient-dark: linear-gradient(
  to bottom,
  $brand-accent-dark,
  $brand-primary-dark
);

$brand-overlay-gradient-light: linear-gradient(
  to bottom,
  $brand-accent-light,
  $brand-primary-light
);

$brand-primary-gradient: linear-gradient(
  to bottom,
  adjust-color($brand-primary-dark, $alpha: -1),
  adjust-color($brand-primary, $alpha: -0.5)
);

$brand-primary-gradient-dark: linear-gradient(
  to bottom,
  adjust-color($brand-primary-dark, $alpha: -1),
  adjust-color($brand-primary-dark, $alpha: -0.5)
);

$brand-primary-gradient-light: linear-gradient(
  to bottom,
  adjust-color($brand-primary-dark, $alpha: -1),
  adjust-color($brand-primary-light, $alpha: -0.5)
);

$brand-gray-gradient: linear-gradient(to bottom, adjust-color(black, $alpha: -0.4), adjust-color(black, $alpha: -0.8));

$brand-gray-gradient-dark: linear-gradient(
  to bottom,
  adjust-color(black, $alpha: -0.2),
  adjust-color(black, $alpha: -0.6)
);

$brand-gray-gradient-light: linear-gradient(
  to bottom,
  adjust-color(black, $alpha: -0.6),
  adjust-color(black, $alpha: -1)
);
