@use '@angular/material' as mat;
@import 'styles/config';
@import 'styles/material';
@import 'styles/mixins';
@include mat.core($custom-typography);
@include mat.all-component-themes($ithriv-theme);

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin ithriv-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .category-color.CONDUCT { color: $brand-category-conduct !important; }
  .category-color.CONNECT { color: $brand-category-connect !important; }
  .category-color.LEARN { color: $brand-category-learn !important; }
  .category-color.PROPOSE { color: $brand-category-propose !important; }

  .category-bg.CONDUCT { background-color: $brand-category-conduct !important; }
  .category-bg.CONNECT { background-color: $brand-category-connect !important; }
  .category-bg.LEARN { background-color: $brand-category-learn !important; }
  .category-bg.PROPOSE { background-color: $brand-category-propose !important; }

  .category-border.CONDUCT { border-color: $brand-category-conduct !important; }
  .category-border.CONNECT { border-color: $brand-category-connect !important; }
  .category-border.LEARN { border-color: $brand-category-learn !important; }
  .category-border.PROPOSE { border-color: $brand-category-propose !important; }

  html,
  body,
  .main {
    font-family: sans-serif;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-size: 100%;
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100vw;
    overflow-x: hidden;
    background-color: $brand-primary-light-25;
    min-height: 100vh;
  }

  markdown {
    h1, h2, h3, h4, h5, h6 {
      margin-top: 2em;
    }
  }

  #browse {
    .header {
      markdown {
        p,
        blockquote,
        li {
          color: white;
        }
      }
    }

    .sub-category > markdown > p {
      margin-left: 2em;
    }
  }

  h1 {
    font-size: 24px;
    color: $brand-primary;

    &.page-title {
      color: $brand-primary;
      font-size: 32px;
      font-weight: bolder;
      margin-top: 1em;
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  h2 {
    margin-top: 0;
    text-transform: uppercase;
    color: $brand-primary;
    font-size: $font-size-larger-4;
    font-weight: 900;

    &.mat-card-title {
      text-align: center;
      margin: 2em 0 1em 0;
    }
  }

  app-commons-dataset-tile h2.mat-card-title, app-commons-project-tile h2.mat-card-title {
    text-transform: none;
  }

  button {
    background-color: transparent;
    border: none;
  }

    .mat-button-toggle-group {
    border: none;
  }

  .mat-button-toggle-group .mat-button-toggle {
      color: $brand-gray-light !important;
      background-color:white !important;
      border: solid;
      margin: 2px
  }

  .mat-button-toggle-group .mat-button-toggle-checked, .mat-button-toggle.checked {
      background-color: $brand-primary-light-100 !important;
      color: $brand-primary !important;
      border: solid $brand-primary;
  }

  button:not([disabled]):hover {
    background-color: rgba(158, 158, 158, 0.2);
  }

  button[disabled] {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }

  .spacer {
    flex: 1 1 auto;
  }

  a {
    text-decoration: none;
    color: $brand-primary;
  }

  .mat-tab-label {
    background-color: white;

    .mat-tab-label-content {
      color: black;
    }

    &.mat-tab-label-active {
      background-color: transparent;

      .mat-tab-label-content {
        color: white;
      }
    }
  }

  .mat-tab-body-content {
    background-color: $brand-primary-light-25;
  }

  .custom-snackbar {
    max-width: 40vw;
    background: $brand-warning-dark;
    color: white;
    .mat-button-wrapper {
      color: white;
    }
    .mat-snack-bar-container {
      max-width: 40vw;
    }
  }

  ::ng-deep .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
  ::ng-deep .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
    background-color: $brand-primary-light;
  }

  #fileUpload {
    ngx-file-drop {
      .file-upload {
        transition: 750ms ease background-color, 750ms ease border;
        background-color: #dddddd;
        border: 1px solid #dddddd;
        border-radius: 50%;
        height: 400px;
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        #dropZoneInstructions {
          color: $brand-gray;
          text-align: center;

          mat-icon {
            font-size: 128px;
            width: 128px;
            height: 128px;
          }
        }
      }

      #fileQueueContainer {
        width: 100%;
      }
    }

    &.hover file-drop .file-upload {
      background-color: $brand-primary;
      border: 1px solid $brand-primary;

      #dropZoneInstructions {
        color: white;
      }
    }
  }

  .mat-drawer-content.mat-sidenav-content {
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: visible;
  }

  ::ng-deep .mat-drawer-backdrop.mat-drawer-shown {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .mat-tooltip {
    font-size: $base-font-size !important;
    background-color: $brand-primary-dark !important;
    padding: $padding-size !important;
    white-space: pre-wrap !important;

    &.warn {
      background-color: $brand-warning-dark !important;
    }
  }

  .mat-card.create-edit,
  .mat-card.view {
    overflow: hidden;

    & > .mat-card-header {
      padding-bottom: 1em;

      .mat-card-header-text {
        padding: 0;
        margin: 0;
      }

      .mat-card-title {
        font-size: $font-size-larger-4;
        color: $brand-primary;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
      }

      .mat-card-subtitle {
        margin-top: 1em;
      }
    }

    .mat-card-actions {
      padding: 0;
      margin: 0;
      margin-bottom: 4px !important;
    }
  }

  .mat-table {
    @include mat.elevation(2);

    .mat-cell {
      padding-left: 1em;
      padding-right: 1em;
    }

    .mat-header-cell {
      padding-left: 1em;
      padding-right: 1em;
      background-color: $brand-primary-light;
      color: white;
      font-size: $base-font-size;
    }
  }

  .mat-snack-bar-container.snackbar-warning {
    background-color: $brand-warning;
  }

  .mat-snack-bar-container.snackbar-success {
    background-color: $brand-primary;
    color: white;
  }


  .preparingCsvData {
    ::ng-deep .mat-button-wrapper {
      color: #666;
    }

    .mat-progress-spinner,
    .mat-spinner {
      display: inline-block;

      ::ng-deep circle {
        stroke: #666;
      }
    }
  }

  .gradient-bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: $brand-overlay-gradient;
    z-index: 0;

    &:hover {
      background-image: $brand-overlay-gradient-light;
    }

    &:active {
      background-image: $brand-overlay-gradient-dark;
    }
  }

  .alert {
    padding: ($padding-size * 2);
    border-radius: 5px;
    margin: 4em;
  }

  .alert.warning {
    border: 4px solid $brand-warning;
    background-color: $brand-warning-light-100;
  }

  .alert.info {
    border: 4px solid $brand-primary;
    background-color: $brand-primary-light-100;
  }

  .ghost {
    opacity: 0;
  }
}
